/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import { getApp, getIntlService } from 'embercom/lib/container-lookup';
import InserterMenuConfiguration, {
  type EdgeParameters,
  type StepParameters,
  type InserterMenuConfigurationParams,
} from 'embercom/objects/visual-builder/configuration/inserter-menu';
import { inserterMenuItems } from 'embercom/objects/visual-builder/configuration/inserter-menu-items-map';
import { stepModelClasses, stepTypes } from 'embercom/objects/visual-builder/configuration-list';
import { assert } from '@ember/debug';
// @ts-ignore
import { cached } from 'tracked-toolbox';
import { tracked } from '@glimmer/tracking';
import { type SyncHasMany } from '@ember-data/model';
import type Step from 'embercom/models/operator/visual-builder/step';
import { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import Group from 'embercom/models/operator/visual-builder/group';
import type IntlService from 'embercom/services/intl';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

type PathConfigParams = {
  path: Group;
  editorState: EditorState;
  entityType?: number;
  trigger?: string;
};

const TICKET_TRIGGER_STEPS = [
  inserterMenuItems.setTicketState,
  inserterMenuItems.setTicketCustomState,
];
const NEW_PHONE_CALL_TRIGGER_STEPS = [
  inserterMenuItems.hangUp,
  inserterMenuItems.holdAndAssign,
  inserterMenuItems.phoneHoldAndAssign,
  inserterMenuItems.forwardCall,
  inserterMenuItems.phoneCallCsatRequest,
  inserterMenuItems.voicemail,
  inserterMenuItems.callback,
];

const INTEGRATION_ACTION_STEPS = [
  inserterMenuItems.sendToHubspot,
  inserterMenuItems.sendToMarketo,
  inserterMenuItems.sendToSalesforce,
  inserterMenuItems.triggerMarketoCampaign,
  inserterMenuItems.createSalesforceCase,
  inserterMenuItems.notifySlackChannel,
  inserterMenuItems.customActionTemplate,
];

export default class PathConfig {
  @tracked steps: SyncHasMany<Step>;

  intl: IntlService;
  path: Group;
  editorState: EditorState;

  constructor({ path, editorState }: PathConfigParams) {
    this.intl = getIntlService();
    this.path = path;
    this.steps = this.path.steps;
    this.editorState = editorState;

    assert('You must specify the path for the path configuration', this.path);
  }

  @cached
  get stepInserterMenu(): InserterMenuConfiguration {
    return new InserterMenuConfiguration({
      excludedItems: [inserterMenuItems.endBot],
      onSelectItem: this.stepInserterOnSelectItem,
      pathConfig: this,
      ...this.stepInserterMenuOptions,
    });
  }

  @cached
  get pathInserterMenu(): InserterMenuConfiguration {
    return new InserterMenuConfiguration({
      excludedItems: [],
      existingPaths: this.editorState.workflow.groups.reject((group) => group === this.path),
      onSelectItem: this.pathInserterOnSelectItem,
      pathConfig: this,
      ...this.pathInserterMenuOptions,
    });
  }

  stepInserterOnSelectItem = ({
    ModelClass,
    stepCreationParams,
    analyticsData,
  }: StepParameters) => {
    let params = {
      group: this.path,
      ModelClass,
      stepCreationParams,
      analyticsData,
    };

    if (ModelClass.isStepGroupEnding && this.path.hasGroupEndingStep) {
      this.editorState.replaceStep({
        replaceAtIndex: Number(this.steps.length) - 1,
        ...params,
      });
    } else {
      this.editorState.createStep({
        insertAtIndex: !this.path.hasGroupEndingStep
          ? Number(this.steps.length)
          : Number(this.steps.length) - 1,
        ...params,
      });
    }
  };

  _pathInserterOnSelectItem(
    { ModelClass, stepCreationParams, customItemHandler, analyticsData }: StepParameters,
    { connectionPoint }: EdgeParameters,
  ) {
    let { editorState } = this;
    if (customItemHandler) {
      return customItemHandler({ connectionPoint, editorState });
    }
    return editorState.createGroupFromConnectionPoint({
      StepModelClass: ModelClass,
      stepCreationParams,
      outwardConnectionPoint: connectionPoint,
      analyticsData,
    });
  }

  // A lambda function, in comparison to a method, keeps the `this` scope of the class instance.
  // In order to allow for class-based inheritance we need to use a method, but call it using a lambda.
  pathInserterOnSelectItem = (stepParams: StepParameters, edgeParams: EdgeParameters) =>
    this._pathInserterOnSelectItem.call(this, stepParams, edgeParams);

  get isAddStepEnabled() {
    return true;
  }

  get stepInserterMenuOptions(): Partial<InserterMenuConfigurationParams> {
    return {};
  }

  get pathInserterMenuOptions(): Partial<InserterMenuConfigurationParams> {
    return {};
  }

  canDeleteStep(_step: Step) {
    return (
      this.path.workflow.groups.length === 1 ||
      (this.steps.length as number) > 1 ||
      !this.path.isStart
    );
  }

  deleteStep(step: Step) {
    this.editorState.deleteStep(step);
  }
}

export class ButtonPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }

  canDeleteStep(step: Step) {
    // cannot delete first step of first path
    if (this.path.isStart && this.steps.indexOf(step) === 0) {
      return false;
    }

    return super.canDeleteStep(step);
  }

  get stepInserterMenuOptions() {
    let excludedItems = [
      inserterMenuItems.endBot,
      inserterMenuItems.endHandover,
      inserterMenuItems.answerTerminal,
      ...TICKET_TRIGGER_STEPS,
      ...NEW_PHONE_CALL_TRIGGER_STEPS,
    ];

    if (this.path.isStart) {
      excludedItems.push(inserterMenuItems.addTagToConversation);
    }

    return { excludedItems };
  }
}

export class InboundPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...(this.path.isStart
          ? Object.values(stepTypes).filter((type) => type !== stepTypes.chatMessage)
          : []),
      ],
    };
  }

  get isAddStepEnabled() {
    return true;
  }

  _pathInserterOnSelectItem(stepParams: StepParameters, edgeParams: EdgeParameters) {
    let { connectionPoint } = edgeParams;
    if (
      connectionPoint.type === 'capture_composer_input' &&
      connectionPoint.edge?.toGroup.isPlaceholder
    ) {
      this.editorState.deleteGroup(connectionPoint.edge.toGroup);
    }

    return super._pathInserterOnSelectItem(stepParams, edgeParams);
  }

  canDeleteStep(step: Step) {
    // must have at least one chat message in starting path
    if (
      this.path.isStart &&
      this.path.steps.filterBy('typeKey', stepTypes.chatMessage).length === 1
    ) {
      return false;
    }

    return super.canDeleteStep(step);
  }
}

export class OutboundPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }

  get isAddStepEnabled() {
    return !this.path.isStart;
  }

  canDeleteStep(step: Step) {
    // cannot delete any steps in starting path
    if (this.path.isStart) {
      return false;
    }

    return super.canDeleteStep(step);
  }
}

export class ReusablePathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return { excludedItems: [inserterMenuItems.endHandover, inserterMenuItems.answerTerminal] };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }
}

export class ConversationStartedTargetPathConfig extends PathConfig {
  excludedItems: string[];

  constructor({ path, editorState }: PathConfigParams) {
    super({ path, editorState });
    this.excludedItems = [
      inserterMenuItems.endHandover,
      inserterMenuItems.answerTerminal,
      ...TICKET_TRIGGER_STEPS,
      ...NEW_PHONE_CALL_TRIGGER_STEPS,
    ];
  }

  get pathInserterMenuOptions() {
    return { excludedItems: this.excludedItems };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [...this.excludedItems, inserterMenuItems.endBot],
    };
  }
}

export class NewPhoneCallTargetPathConfig extends PathConfig {
  excludedItems: string[];

  constructor({ path, editorState }: PathConfigParams) {
    super({ path, editorState });
    this.excludedItems = [
      inserterMenuItems.answerTerminal,
      inserterMenuItems.fin,
      inserterMenuItems.freeInput,
      inserterMenuItems.triggerWorkflow,
      inserterMenuItems.disableComposer,
      inserterMenuItems.wait,
      inserterMenuItems.snooze,
      inserterMenuItems.conversationRatings,
      inserterMenuItems.endHandover,
      inserterMenuItems.attributeCollector,
      inserterMenuItems.messengerApp,
      inserterMenuItems.sendTicket,
      inserterMenuItems.applyRules,
      inserterMenuItems.customObjectSelector,
      inserterMenuItems.setExpectations,
      inserterMenuItems.summarizeConversation,
      inserterMenuItems.convertToTicket,
      ...TICKET_TRIGGER_STEPS,
    ];

    this.excludedItems = [...this.excludedItems, inserterMenuItems.assignConversation];

    this.excludedItems = [
      ...this.excludedItems,
      inserterMenuItems.closeConversation,
      inserterMenuItems.applyConversationSla,
    ];
  }

  get pathInserterMenuOptions() {
    return { excludedItems: this.excludedItems };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [...this.excludedItems, inserterMenuItems.endBot],
    };
  }
}

export class NewCommentTargetPathConfig extends PathConfig {
  excludedItems: string[];

  constructor({ path, editorState }: PathConfigParams) {
    super({ path, editorState });
    this.excludedItems = [
      inserterMenuItems.endHandover,
      inserterMenuItems.answerTerminal,
      ...(getApp().isAiClassificationKillswitchEnabled
        ? [inserterMenuItems.classifyConversationAttribute]
        : []),
      ...TICKET_TRIGGER_STEPS,
      ...NEW_PHONE_CALL_TRIGGER_STEPS,
    ];
  }

  get pathInserterMenuOptions() {
    return { excludedItems: this.excludedItems };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [...this.excludedItems, inserterMenuItems.endBot],
    };
  }
}

export class ConversationStatusChangedPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }
}

export class ConversationAssignmentChangedPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.answerTerminal,
        inserterMenuItems.endHandover,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.answerTerminal,
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }
}

export class UserInactivityPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }
}

export class AdminInactivityPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.answerTerminal,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }
}

export class CustomAnswerPathConfig extends PathConfig {
  get stepInserterMenuOptions() {
    let app = getApp();
    return {
      excludedItems: [
        inserterMenuItems.fin,
        inserterMenuItems.sendTicket,
        ...(app.canUseFeature('mid-path-reusable-workflows')
          ? []
          : [inserterMenuItems.triggerWorkflow]),
        inserterMenuItems.disableComposer,
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.conversationRatings,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }

  get pathInserterMenuOptions() {
    let app = getApp();
    return {
      excludedItems: [
        inserterMenuItems.fin,
        inserterMenuItems.sendTicket,
        ...(app.canUseFeature('mid-path-reusable-workflows')
          ? []
          : [inserterMenuItems.triggerWorkflow]),
        inserterMenuItems.disableComposer,
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        inserterMenuItems.conversationRatings,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
      onSelectItem: (
        { ModelClass, stepCreationParams, customItemHandler }: StepParameters,
        { connectionPoint }: EdgeParameters,
      ) => {
        let newGroup = this.pathInserterOnSelectItem(
          { ModelClass, stepCreationParams, customItemHandler },
          { connectionPoint },
        );

        if (newGroup instanceof Group) {
          this.maybeCreateDefaultAnswerTerminalStep(newGroup);
        }

        return newGroup;
      },
    };
  }

  maybeCreateDefaultAnswerTerminalStep(group: Group) {
    if (group.hasGroupEndingStep) {
      return; // This group already has a group ending step
    }

    if ((group.constructor as typeof Group).modelName !== 'operator/visual-builder/group') {
      return; // Incoming element is not a group. This happens when an inserter item is handled by a custom handler (such as connecting to an existing group) and the custom handler returns something other than a group object (or null)
    }

    // Create the answer terminal step
    this.editorState.createStep({
      group,
      ModelClass: stepModelClasses[stepTypes.answerTerminal],
      insertAtIndex: Number(group.steps.length),
    });
  }

  canDeleteStep(step: Step) {
    // cannot delete first step of first path
    if (this.path.isStart && this.steps.indexOf(step) === 0) {
      return false;
    } else if (
      (this.steps.length as number) > 1 &&
      step.type === 'operator/visual-builder/step/answer-terminal'
    ) {
      return false;
    }

    return super.canDeleteStep(step);
  }

  deleteStep(step: Step) {
    let group = step.group;
    super.deleteStep(step);
    this.maybeCreateDefaultAnswerTerminalStep(group);
  }
}

export class AiAgentProfilePathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.answerTerminal,
        inserterMenuItems.fin,
        inserterMenuItems.endBot,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.answerTerminal,
        inserterMenuItems.fin,
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }
}

export class BackgroundTriggerPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...Object.keys(stepModelClasses).filter(
          (stepType: keyof typeof stepModelClasses) =>
            stepModelClasses[stepType].canBeCustomerFacing === IsCustomerFacing.Yes,
        ),
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.endBot,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...Object.keys(stepModelClasses).filter(
          (stepType: keyof typeof stepModelClasses) =>
            stepModelClasses[stepType].canBeCustomerFacing === IsCustomerFacing.Yes,
        ),
      ],
    };
  }
}

export class NewAdminCommentPathConfig extends BackgroundTriggerPathConfig {
  excludedItems: string[];

  constructor({ path, editorState }: PathConfigParams) {
    super({ path, editorState });
    this.excludedItems = [
      ...(getApp().canUseAiConversationClassification
        ? [inserterMenuItems.classifyConversationAttribute]
        : []),
    ];
  }
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        ...super.pathInserterMenuOptions.excludedItems,
        ...TICKET_TRIGGER_STEPS,
        ...this.excludedItems,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        ...super.stepInserterMenuOptions.excludedItems,
        ...TICKET_TRIGGER_STEPS,
        ...this.excludedItems,
      ],
    };
  }
}
export class AdminNoteCreatedPathConfig extends BackgroundTriggerPathConfig {
  @tracked targetContexts: Readonly<TargetContext[]>;

  constructor({
    path,
    editorState,
    targetContexts,
  }: PathConfigParams & { targetContexts: TargetContext[] }) {
    super({ path, editorState });
    this.targetContexts = targetContexts;
  }

  get excludedStepTypes() {
    return Object.keys(stepModelClasses).filter((stepType: keyof typeof stepModelClasses) => {
      let unsupportedContexts = this.targetContexts.filter(
        (context) => !stepModelClasses[stepType].supportedContexts.includes(context),
      );
      return unsupportedContexts.length > 0;
    });
  }

  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        ...super.pathInserterMenuOptions.excludedItems,
        ...TICKET_TRIGGER_STEPS,
        ...this.excludedStepTypes,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        ...super.stepInserterMenuOptions.excludedItems,
        ...TICKET_TRIGGER_STEPS,
        ...this.excludedStepTypes,
      ],
    };
  }
}
export class ConversationAttributeChangedPathConfig extends BackgroundTriggerPathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [...super.pathInserterMenuOptions.excludedItems, ...TICKET_TRIGGER_STEPS],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [...super.stepInserterMenuOptions.excludedItems, ...TICKET_TRIGGER_STEPS],
    };
  }
}

export class TicketTriggerPathConfig extends PathConfig {
  excludedItems: string[];
  @tracked targetContexts: Readonly<TargetContext[]>;

  constructor({
    path,
    editorState,
    targetContexts,
  }: PathConfigParams & { targetContexts: TargetContext[] }) {
    super({ path, editorState });
    this.targetContexts = targetContexts;
    this.excludedItems = [
      ...(getApp().canUseAiConversationClassification
        ? [inserterMenuItems.classifyConversationAttribute]
        : []),
    ];
  }

  get excludedStepTypes() {
    let app = getApp();

    if (app.canUseWorkflowsTicketsV3) {
      return Object.keys(stepModelClasses).filter((stepType: keyof typeof stepModelClasses) => {
        let unsupportedContexts = this.targetContexts.filter(
          (context) => !stepModelClasses[stepType].supportedContexts.includes(context),
        );
        return unsupportedContexts.length > 0;
      });
    } else {
      return Object.keys(stepModelClasses).filter(
        (stepType: keyof typeof stepModelClasses) =>
          stepModelClasses[stepType].canBeCustomerFacing === IsCustomerFacing.Yes,
      );
    }
  }

  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...this.excludedStepTypes,
        ...this.excludedItems,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.endHandover,
        inserterMenuItems.endBot,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
        ...this.excludedStepTypes,
        ...this.excludedItems,
      ],
    };
  }
}

export class WebhookReceivedPathConfig extends BackgroundTriggerPathConfig {
  private readonly targetContexts: TargetContext[] = [
    TargetContext.CustomerTicket,
    TargetContext.BackOfficeTicket,
  ];

  get excludedStepTypes() {
    return Object.keys(stepModelClasses).filter((stepType: keyof typeof stepModelClasses) => {
      let unsupportedContexts = this.targetContexts.filter(
        (context) => !stepModelClasses[stepType].supportedContexts.includes(context),
      );
      return unsupportedContexts.length > 0;
    });
  }

  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.workflowConnector,
        ...INTEGRATION_ACTION_STEPS,
        ...super.pathInserterMenuOptions.excludedItems,
        ...this.excludedStepTypes,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.workflowConnector,
        ...INTEGRATION_ACTION_STEPS,
        ...super.stepInserterMenuOptions.excludedItems,
        ...this.excludedStepTypes,
      ],
    };
  }
}

export class AiAgentWorkflowPathConfig extends PathConfig {
  get pathInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.answerTerminal,
        inserterMenuItems.fin,
        inserterMenuItems.endBot,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }

  get stepInserterMenuOptions() {
    return {
      excludedItems: [
        inserterMenuItems.answerTerminal,
        inserterMenuItems.fin,
        inserterMenuItems.endBot,
        inserterMenuItems.endHandover,
        ...TICKET_TRIGGER_STEPS,
        ...NEW_PHONE_CALL_TRIGGER_STEPS,
      ],
    };
  }
}
