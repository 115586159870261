/* RESPONSIBLE TEAM: team-workflows */
/* eslint-disable no-restricted-imports */

import type StepConfig from 'embercom/objects/visual-builder/configuration/step';
import PathConfig from 'embercom/objects/visual-builder/configuration/path';
import { stepTypes } from 'embercom/objects/visual-builder/configuration-list';
import {
  getAttributeService,
  getApp,
  getEmberDataStore,
  getIntlService,
} from 'embercom/lib/container-lookup';

// Step Configuration Classes
import CallbackConfiguration from './step/callback';
import RemoveTagFromConversationConfiguration from './step/remove-tag-from-conversation';
import SetTicketStateConfiguration from './step/set-ticket-state';
import SetTicketCustomStateConfiguration from './step/set-ticket-custom-state';
import SummarizeConfiguration from './step/summarize-conversation';
import ClassifyConfiguration from './step/classify-conversation-attribute';
import WaitConfiguration from './step/wait';
import SnoozeConfiguration from './step/snooze';
import AddTagToConversationConfiguration from 'embercom/objects/visual-builder/configuration/step/add-tag-to-conversation';
import AnswerBotConfiguration from 'embercom/objects/visual-builder/configuration/step/answer-bot';
import FinConfiguration from 'embercom/objects/visual-builder/configuration/step/fin';
import AnswerTerminalConfiguration from 'embercom/objects/visual-builder/configuration/step/answer-terminal';
import ApplyConversationSlaConfiguration from 'embercom/objects/visual-builder/configuration/step/apply-conversation-sla';
import AssignConversationConfiguration from 'embercom/objects/visual-builder/configuration/step/assign-conversation';
import AssignConversationToOwnerConfiguration from 'embercom/objects/visual-builder/configuration/step/assign-conversation-to-owner';
import AttributeCollectorConfiguration from 'embercom/objects/visual-builder/configuration/step/attribute-collector';
import ChangeConversationPriorityConfiguration from 'embercom/objects/visual-builder/configuration/step/change-conversation-priority';
import ChatMessageConfiguration from 'embercom/objects/visual-builder/configuration/step/chat-message';
import CloseConversationConfiguration from 'embercom/objects/visual-builder/configuration/step/close-conversation';
import ConditionalBranchesConfiguration from 'embercom/objects/visual-builder/configuration/step/conditional-branches';
import CreateSalesforceCaseConfiguration from 'embercom/objects/visual-builder/configuration/step/create-salesforce-case';
import CustomObjectSelectorConfiguration from 'embercom/objects/visual-builder/configuration/step/custom-object-selector';
import DisableComposerConfiguration from 'embercom/objects/visual-builder/configuration/step/disable-composer';
import FreeInputConfiguration from 'embercom/objects/visual-builder/configuration/step/free-input';
import MessengerAppConfiguration from 'embercom/objects/visual-builder/configuration/step/messenger-app';
import ReplyButtonsConfiguration from 'embercom/objects/visual-builder/configuration/step/reply-buttons';
import SendTicketConfiguration from 'embercom/objects/visual-builder/configuration/step/send-ticket';
import SendToHubspotConfiguration from 'embercom/objects/visual-builder/configuration/step/send-to-hubspot';
import SendToMarketoConfiguration from 'embercom/objects/visual-builder/configuration/step/send-to-marketo';
import SendToSalesforceConfiguration from 'embercom/objects/visual-builder/configuration/step/send-to-salesforce';
import SetConversationDataAttributeConfiguration from 'embercom/objects/visual-builder/configuration/step/set-conversation-data-attribute';
import SetUserDataAttributeConfiguration from 'embercom/objects/visual-builder/configuration/step/set-user-data-attribute';
import SetExpectationsConfiguration from 'embercom/objects/visual-builder/configuration/step/set-expectations';
import ConvertToTicketConfiguration from 'embercom/objects/visual-builder/configuration/step/convert-to-ticket';
import TagUserConfiguration from 'embercom/objects/visual-builder/configuration/step/tag-user';
import TriggerMarketoCampaignConfiguration from 'embercom/objects/visual-builder/configuration/step/trigger-marketo-campaign';
import TriggerWorkflowConfiguration from 'embercom/objects/visual-builder/configuration/step/trigger-workflow';
import WorkflowConnectorConfiguration from 'embercom/objects/visual-builder/configuration/step/workflow-connector';
import type Group from 'embercom/models/operator/visual-builder/group';
import type EditorState from 'embercom/objects/workflows/graph-editor/editor-state';
import type Step from 'embercom/models/operator/visual-builder/step';
import ConversationRatingsConfiguration from './step/conversation-ratings';
import ApplyRulesConfiguration from './step/apply-rules';
import NoteConfiguration from './step/note';
import NotifySlackChannelConfiguration from './step/notify-slack-channel';
import HangUpConfiguration from './step/hang-up';
import HoldAndAssignConfiguration from './step/hold-and-assign';
import PhoneHoldAndAssignConfiguration from './step/phone-hold-and-assign';
import ForwardCallConfiguration from './step/forward-call';
import GithubCreateIssueConfiguration from './step/github-create-issue';
import PhoneCallCsatRequestConfiguration from './step/phone-call-csat-request';
import VoicemailConfiguration from './step/voicemail';
import RemoveTagFromUserConfiguration from 'embercom/objects/visual-builder/configuration/step/remove-tag-from-user';
import SetLanguageOverrideConfiguration from './step/set-language-override';

export type PathConfigGenerationParams = {
  path: Group;
  editorState: EditorState;
};

export type StepConfigGenerationParams<S extends Step> = {
  step: S;
  pathConfig: PathConfig;
};

export type AttributeGroup = { heading?: string; attributes: any[] };
export type AttributeGroupList = Array<AttributeGroup>;

export default class EditorConfig {
  public areStepPaywallsActive: boolean;
  protected attributeService: any;
  protected app: any;
  protected store: any;
  protected intl: any;
  public readonly isBackgroundOnly: boolean = false;
  public readonly canBeTicketTrigger: boolean = false;

  constructor({ areStepPaywallsActive }: { areStepPaywallsActive?: boolean } = {}) {
    this.areStepPaywallsActive = areStepPaywallsActive ?? false;
    this.attributeService = getAttributeService();
    this.app = getApp();
    this.store = getEmberDataStore();
    this.intl = getIntlService();
  }

  generatePathConfig({ path, editorState }: PathConfigGenerationParams): PathConfig {
    return new PathConfig({ path, editorState });
  }

  generateStepConfig<S extends Step>({
    step,
    pathConfig,
  }: StepConfigGenerationParams<S>): StepConfig {
    let stepConfigArgs = { step, pathConfig };

    switch (step.typeKey) {
      case stepTypes.wait:
        return new WaitConfiguration(stepConfigArgs);
      case stepTypes.snooze:
        return new SnoozeConfiguration(stepConfigArgs);
      case stepTypes.addTagToConversation:
        return new AddTagToConversationConfiguration(stepConfigArgs);
      case stepTypes.answerBot:
        return new AnswerBotConfiguration(stepConfigArgs);
      case stepTypes.fin:
        return new FinConfiguration(stepConfigArgs);
      case stepTypes.answerTerminal:
        return new AnswerTerminalConfiguration(stepConfigArgs);
      case stepTypes.applyConversationSla:
        return new ApplyConversationSlaConfiguration(stepConfigArgs);
      case stepTypes.assignConversation:
        return new AssignConversationConfiguration(stepConfigArgs);
      case stepTypes.assignConversationToOwner:
        return new AssignConversationToOwnerConfiguration(stepConfigArgs);
      case stepTypes.attributeCollector:
        return new AttributeCollectorConfiguration(stepConfigArgs);
      case stepTypes.changeConversationPriority:
        return new ChangeConversationPriorityConfiguration(stepConfigArgs);
      case stepTypes.chatMessage:
        return new ChatMessageConfiguration({
          ...stepConfigArgs,
          attributeService: this.attributeService,
        });
      case stepTypes.closeConversation:
        return new CloseConversationConfiguration(stepConfigArgs);
      case stepTypes.conditionalBranches:
        return new ConditionalBranchesConfiguration(stepConfigArgs);
      case stepTypes.applyRules:
        return new ApplyRulesConfiguration(stepConfigArgs);
      case stepTypes.createSalesforceCase:
        return new CreateSalesforceCaseConfiguration(stepConfigArgs);
      case stepTypes.customObjectSelector:
        return new CustomObjectSelectorConfiguration(stepConfigArgs);
      case stepTypes.disableComposer:
        return new DisableComposerConfiguration(stepConfigArgs);
      case stepTypes.freeInput:
        return new FreeInputConfiguration(stepConfigArgs);
      case stepTypes.messengerApp:
        return new MessengerAppConfiguration(stepConfigArgs);
      case stepTypes.note:
        return new NoteConfiguration({
          ...stepConfigArgs,
          attributeService: this.attributeService,
        });
      case stepTypes.replyButtons:
        return new ReplyButtonsConfiguration(stepConfigArgs);
      case stepTypes.sendTicket:
        return new SendTicketConfiguration(stepConfigArgs);
      case stepTypes.sendToHubspot:
        return new SendToHubspotConfiguration(stepConfigArgs);
      case stepTypes.sendToMarketo:
        return new SendToMarketoConfiguration(stepConfigArgs);
      case stepTypes.sendToSalesforce:
        return new SendToSalesforceConfiguration(stepConfigArgs);
      case stepTypes.setConversationDataAttribute:
        return new SetConversationDataAttributeConfiguration(stepConfigArgs);
      case stepTypes.setUserDataAttribute:
        return new SetUserDataAttributeConfiguration(stepConfigArgs);
      case stepTypes.setExpectations:
        return new SetExpectationsConfiguration(stepConfigArgs);
      case stepTypes.setLanguageOverride:
        return new SetLanguageOverrideConfiguration(stepConfigArgs);
      case stepTypes.convertToTicket:
        return new ConvertToTicketConfiguration(stepConfigArgs);
      case stepTypes.tagUser:
        return new TagUserConfiguration(stepConfigArgs);
      case stepTypes.triggerMarketoCampaign:
        return new TriggerMarketoCampaignConfiguration(stepConfigArgs);
      case stepTypes.triggerWorkflow:
        return new TriggerWorkflowConfiguration(stepConfigArgs);
      case stepTypes.workflowConnector:
        return new WorkflowConnectorConfiguration({
          ...stepConfigArgs,
          attributeService: this.attributeService,
          app: this.app,
        });
      case stepTypes.conversationRatings:
        return new ConversationRatingsConfiguration(stepConfigArgs);
      case stepTypes.setTicketState:
        return new SetTicketStateConfiguration(stepConfigArgs);
      case stepTypes.setTicketCustomState:
        return new SetTicketCustomStateConfiguration(stepConfigArgs);
      case stepTypes.summarizeConversation:
        return new SummarizeConfiguration(stepConfigArgs);
      case stepTypes.classifyConversationAttribute:
        return new ClassifyConfiguration(stepConfigArgs);
      case stepTypes.notifySlackChannel:
        return new NotifySlackChannelConfiguration(stepConfigArgs);
      case stepTypes.hangUp:
        return new HangUpConfiguration(stepConfigArgs);
      case stepTypes.holdAndAssign:
        return new HoldAndAssignConfiguration(stepConfigArgs);
      case stepTypes.phoneHoldAndAssign:
        return new PhoneHoldAndAssignConfiguration(stepConfigArgs);
      case stepTypes.forwardCall:
        return new ForwardCallConfiguration(stepConfigArgs);
      case stepTypes.githubCreateIssue:
        return new GithubCreateIssueConfiguration(stepConfigArgs);
      case stepTypes.removeTagFromConversation:
        return new RemoveTagFromConversationConfiguration(stepConfigArgs);
      case stepTypes.phoneCallCsatRequest:
        return new PhoneCallCsatRequestConfiguration(stepConfigArgs);
      case stepTypes.voicemail:
        return new VoicemailConfiguration(stepConfigArgs);
      case stepTypes.callback:
        return new CallbackConfiguration(stepConfigArgs);
      case stepTypes.removeTagFromUser:
        return new RemoveTagFromUserConfiguration(stepConfigArgs);
      default:
        throw new Error(`Unknown step type key: ${step.typeKey}`);
    }
  }

  /*
   * Return a list of attribute groups that should be usable in the predicates editor.
   * This list will control the attributes available to use at the workflow matching stage.
   * Used in the predicates editor shown in the trigger side panel.
   */
  get workflowMatchingTargetingAttributes(): AttributeGroupList | null {
    return null;
  }

  get workflowMatchingTargetingAttributeIdentifiers(): string[] {
    return this.workflowMatchingTargetingAttributes !== null
      ? this.groupAndFormatAttributes(this.workflowMatchingTargetingAttributes)
      : [];
  }

  /*
   * Return a list of attribute groups that should be usable in the predicates editor.
   * This list will control the attributes available to use at workflow runtime.
   * Used in the predicates editor shown in the conditional branching step.
   */
  get runtimeMatchingTargetingAttributes(): AttributeGroupList {
    return this.attributeService.visualBotBuilderConditionalAttributesGroupList;
  }

  get runtimeMatchingTargetingAttributeIdentifiers(): string[] {
    return this.groupAndFormatAttributes(this.runtimeMatchingTargetingAttributes);
  }

  get localAttributes(): AttributeGroupList | null {
    return null;
  }

  /*
   * Whether teammates can select which channels the workflow will target or not.
   */
  get supportsConversationChannelTargeting(): boolean {
    return true;
  }

  /*
   * Whether teammates can use user & company based targeting features
   */
  get supportsUserTargeting(): boolean {
    return true;
  }

  /*
   * Controls whether the audience preview should be shown
   * when editing workflow targeting predicates
   */
  get showAudiencePreview(): boolean {
    return true;
  }

  /*
   * Controls whether the goal panel should be shown
   * when editing workflow targeting predicates
   */
  get showGoalsPanel(): boolean {
    return true;
  }

  /*
   * Controls what the audience targeting section should be titled in workflows
   * */
  get targetingSectionTitle(): string {
    return this.intl.t('operator.workflows.visual-builder.workflow-trigger-node.audience');
  }

  /*
   * Controls whether a side sheet should be shown at all when clicking on the trigger
   */
  get supportsSideSheet(): boolean {
    return true;
  }

  /*
   * Controls what the audience attributes picker button should be labeled in workflows
   * */
  get targetingAttributePickerLabel(): string {
    return this.intl.t('matching-system.audience-selector.audience-rule-editor.add-audience-rule');
  }

  private groupAndFormatAttributes(attributes: AttributeGroupList): string[] {
    return attributes
      .map((group) => group.attributes)
      .flat()
      .map((attribute) => attribute.identifier)
      .flatMap((attribute) =>
        attribute.startsWith('user_event_summaries.')
          ? [`${attribute}.count`, `${attribute}.first`, `${attribute}.last`]
          : attribute,
      );
  }
}
