/* RESPONSIBLE TEAM: team-data-interop */

import EditorConfig, {
  type AttributeGroupList,
} from 'embercom/objects/visual-builder/configuration/editor';
import type { PathConfigGenerationParams } from 'embercom/objects/visual-builder/configuration/editor';
import { AiAgentWorkflowPathConfig } from 'embercom/objects/visual-builder/configuration/path';
import type InsertableAction from 'embercom/models/workflow-connector/insertable-action';
import type InsertableActionField from 'embercom/models/workflow-connector/insertable-action-field';

export default class AiAgentWorkflowEditorConfig extends EditorConfig {
  public actions: any;

  constructor({
    areStepPaywallsActive,
    actions,
  }: {
    areStepPaywallsActive?: boolean;
    actions?: any;
  }) {
    super({ areStepPaywallsActive });
    this.actions = actions;
  }

  generatePathConfig({ path, editorState }: PathConfigGenerationParams) {
    return new AiAgentWorkflowPathConfig({ path, editorState });
  }

  get showGoalsPanel(): boolean {
    return false;
  }

  get localAttributes(): AttributeGroupList | null {
    return this.actionResponseFieldAttributes;
  }

  get actionResponseFieldAttributes(): AttributeGroupList {
    return this.actions?.map((action: InsertableAction) => {
      return {
        heading: action.name,
        attributes: action.responseFields.map((field: InsertableActionField) => {
          return {
            identifier: `operator_workflow_trigger.data.action_id_${action.id}.${field.identifier}`,
            templatable_identifier: `operator_workflow_trigger.data.action_id_${action.id}.${field.identifier}`,
            composer_name: `${action.name} > ${field.name}`,
            name: field.name,
            type: field.type,
            icon: field.icon,
          };
        }),
      };
    });
  }

  get runtimeMatchingTargetingAttributes(): AttributeGroupList {
    let groups = [
      ...this.actionResponseFieldAttributes,
      ...this.attributeService.attributesToGroupList(
        this.attributeService.visualBotBuilderConditionalAttributes,
      ),
    ];

    return groups.sort((a, b) => {
      if (!a.heading && b.heading) {
        return -1;
      }
      if (a.heading && !b.heading) {
        return 1;
      }
      return 0;
    });
  }
}
